<template>
    <div :class="rootClasses">
        <div class="container">
            <div class="footer-inner">
                <div class="row">
                    <div class="col-6 col-md-3">
                        <div class="info">
                            <a class="info-item" v-for="(inf, key) in menu" :key="key" :href="inf.url">{{ link(inf.title)
                            }}</a>
                        </div>
                    </div>
                    <div class="col-6 col-md-3">
                        <div class="info">
                            <span v-html="data.copyright"></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    components: {
        MenuItem: () => import('../blocks_components/MenuBlockItem.vue')
    },
    data() {
        return {
            menu: [],
            labels: {
                catalog: 'Catalog',
                information: 'Information',
                contacts: 'Contacts',
                email: 'Email: ',
                schedule: 'Work schedule: '
            }
        }
    },
    props: {
        data: {
            type: Object,
            default: () => { }
        }
    },
    computed: {
        homeUrl() {
            return window.location.origin
        },
        rootClasses() {
            let result = {
                ['cs-footer']: true,
                ['theme-block']: true,
                ['has-background'] : true
            }

            if (this.data?.hasBackground)
                result = {
                    ...result,
                    ...{
                        ['has-background']: true,
                        ['theme-bg-colored']: true
                    }
                }

            return result
        }
    },
    methods: {
        link(link) {
            return link.charAt(0).toUpperCase() + link.slice(1, -1)
        }
    },
    mounted() {
        let self = this
            ; (async () => {
                self.retrieve_menu(3, 0, 0).then((menu_response) => {
                    Object.keys(menu_response.data).forEach((e) => {
                        this.menu.push(menu_response.data[e])
                    })
                })
            })()
    }
}
</script>

<style lang="scss" scoped>
@import '../styles/main.scss';

.theme-block {
    padding-bottom: 0;
    padding-top: 0;
    background-color: $primary_text_color;
    padding: 80px 0;
}

.footer-inner {
    .row {
        row-gap: $block_space_md;
    }

    .info {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: flex-start;
        gap: 20px;
        font-size: $regular_text_size;
        color: $labels_color;
    }

    .info {
        color: $labels_color;

        ::v-deep {
            * {
                color: $labels_color;
            }

            a {
                color: $labels_color;
                text-decoration: none;
                cursor: pointer;

                &:hover {
                    opacity: 0.7;
                }
            }
        }

        @media (max-width: 575px) {
            padding-left: 10%;
            padding-right: 10%;
        }
    }
}
</style>
